import { Component} from '@angular/core';
import { HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/core/auth/auth.service';
import { environment } from '../environments/environment';


declare const gtag: Function;

@HostListener('window:popstate', ['$event'])

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
      public router:Router,
      public authService: AuthService,
  ) {
if (environment.production) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
      /** END */
    })
  }}
  onPopState(event) {
    location.reload()
  }
  ngOnInit(){
  }

}
