<head><base href="/"></head>
<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
<router-outlet></router-outlet>



<!-- <div style="padding: 2rem; position: fixed; bottom: 0; left: 25%; display: block; background: white; max-height: 15rem; overflow-y: auto; ">
  <h3>PLAN</h3>
  <pre>{{(authService.userLoggedPlanObs | async) | json}}</pre>
  <h3>STATS</h3>
  <pre>NB SITES : {{(authService.userLoggedNbWebsitesObs | async)}}</pre>
  <pre>{{(authService.userLoggedWebSitesAnalyseObs | async) | json}}</pre>
</div> -->
